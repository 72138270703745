jQuery(function($){
    "use strict";

    var page = 2;
    $("#loadMore").click(function () {
        $.post(localizedData.ajaxurl, {
            action: 'load_success_stories',
            page: page
        }, function (response) {
            $('#loadMoreTarget').append(response.items);

            if (response.finished) {
                $("#loadMore").hide();
            }

            page++;
        })
    });
});