jQuery(function($){
    "use strict";

    $(".popup-activate").click(function(e) {
        e.preventDefault();
		if($(this).closest(".popup").length) {
			$(this).closest(".popup").hide();
			$($(this).attr("href")).css('top',$(document).scrollTop()).fadeIn(500);
		} else {
			$($(this).attr("href")).css('top',$(document).scrollTop()).fadeIn(500);
			$(".popup-overlay").fadeIn(500);
		}
    });

    $(document).click(function(e) {
 		e.stopPropagation();
        if ($(event.target).closest(".ninja-forms-field").length) {return;}
        if ($(event.target).closest(".popup-body").length) {return;}
        if ($(event.target).closest(".popup-activate").length) {return;}
		$(".popup-overlay").hide();
		$(".popup").hide();
    });
});